import React from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaTasks,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {
  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>DLEVIS GENERAL INVESTMENTS LTD</strong> is a prominent and duly registered Engineering Company that specializes in Construction Works, Architectural & Structural Plans, Project Management Services, Environmental Impact Assessments, Feasibility Studies, among other Technical Services. Our happy clients include; Government Ministries & Departments, Non-Governmental Organizations, Places of Worship, Schools and Universities, Financial Institutions and Private Individauls. We’re here to meet your technical needs.</p>
<p className='home-p'>We are proactive in finding solutions for our clients that best match their goals. We understand that lasting relationships are the lifeblood of our business so we pay personal attention to our clients. More so, we remain true to our founding values of quality, honesty and hard work. We “do the right thing” and our business is based on trust. Our company is consistently associated with the high standards of service, quality, personal attention to clients, and integrity.</p>


 
        </div>

    </section>
  <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(83, 10, 91, 0.811), rgba(0, 0, 0, 0.711)), url("./images/31.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Slogan</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>A company with a culture of excellence in Project, Time, Cost and Quality Management.
</p>
        </div>
    </section>
    
 <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2> */}
              <div className='goals-list goals-list1'>
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div> */}

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description goal-description1'>We build, maintain and perpetuate a loyal and continuing relationship with every customer through total commitment to quality, transparent dealings and timely completion.</p>
                     </div>

<Link to="about-us" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>




       </div>

  </section>

   <section className='home-service-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'> <div className='line2'></div></div>
   
    <div className='home-services-container '>
      {/* <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div> */}
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Passion</h3>
          <p>We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>We exhibit the highest level of professionalism in all our dealings! We also comply with stringent Health and Safety regulations, which are essential both to our clients and the safety of our workforce.</p>

        </div>
        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTools></FaTools></span>
          </div>
          <h3 className='home-service-title'>Construction & Installation</h3>
          <p>We provide upto six (6) employment teams, tools and transport to handle survey, Right-of-Way, material supply & installation, optical fiber termination & testing, commissioning, acceptance and handover.</p>
        </div> */}
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaUsers></FaUsers></span>
          </div>
          <h3 className='home-service-title'>Experienced Team</h3>
          <p>
           Our team is fully conversant with current planning laws and their experience and expert advice allows clients to make informed choices about the design of their projects.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Certifications</h3>
          <p>As a company, we are registered with the relevant authorities and our entire workforce holds the appropriate qualifications and certifications.</p>
        </div>


        

        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Our Approach</h3>
          <p>Our dedication to Respect, Integrity, Diversity, and Reliability has been the path to success for our company, our employees, and our clients. Our strength lies in our ability to find solutions. </p>
        </div> */}

        
    
    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section>
   
    
  
    </>
  )
}

export default Home