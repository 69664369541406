import React from 'react'

let ValuesData = [

{title: "Respect",      
content: "We respect and honor our customers, staff and our stake holders."
},

{title: "Integrity",      
content: "We value honesty and trustworthiness in our works."
},
{title: "Teamwork",      
content: "Our effort as a team can achieve more than as individuals."
},
{title: "Creativity",      
content: "We are driven by creativity and innovation in our services."
}
];

export default ValuesData;