import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/kas2.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">DLEVIS GENERAL INVESTMENTS LTD

</h3>
          {/* <p className="slide-p slide-p1">"Service with quality"</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/kas3.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p">Providing High-Quality Construction Services</p>
        </Carousel.Caption>
      </Carousel.Item>
      

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
           We're trusted by Government Agencies, NGOs, Business Entities & Private Individuals
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/project2.jpeg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
           We also offer Project Management Services
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFade;