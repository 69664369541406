import Carousel from 'react-bootstrap/Carousel';

function VisionCarousel() {
  return (
    <Carousel indicators={false}>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/30.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/32.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/kas1.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

        <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/kas2.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/kas3.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/project2.jpeg"
          alt="First slide"
        />    
      </Carousel.Item>
     

    </Carousel>
  );
}

export default VisionCarousel;