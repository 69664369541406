import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/project.jpeg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/project2.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>

     
      
    </Carousel>
  );
}

export default PowerCarousel;